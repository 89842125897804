import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Typography
} from "antd"
import React, {useEffect, useState} from "react"
import "./style.css"
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons"
import {useListSellerQuery} from "../../redux/api/services/seller"
import {useListMastersQuery} from "../../redux/api/services/master"
import {useCreateListingMutation, useGetListingQuery, useUpdateListingMutation,} from "../../redux/api/services/listing"
import {convertDayjsToDate, dimensionsText, displayDateFormat, errorAlert, successAlert, onlyNumber} from "../../utils/helper"
import ImageMultiUpload from "../../components/ImageMulitiUpload"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import VideoMultiUpload from "../../components/VideoMultiUpload"
import dayjs from "dayjs"
import {useCreateMeasurementMutation} from "../../redux/api/services/measurement";
import {useCreateAppearanceMutation} from "../../redux/api/services/appearance";


const {Option} = Select;

const ListingAction = () => {
    //Hooks
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const {id} = useParams();
    const location = useLocation();
    const [directSold, setDirectSold] = useState(true);


    const [allSeller, setAllSeller] = useState([]);

    //Api
    const {data: listingView, isLoading: listingViewLoading} = useGetListingQuery(id, {
        skip: id ? false : true,
    })

    const {data: masterData, isLoading: masterLoading} = useListMastersQuery()
    const {
        data: sellerData,
        isLoading: sellerLoading
    } = useListSellerQuery('select=id,name,email,paypal_merchant_status')

    const [createListing, {isLoading: createLoading}] = useCreateListingMutation()

    const [updateListing, {isLoading: updateLoading}] = useUpdateListingMutation()

    const [createMeasurement, {isLoading: mesLoading}] = useCreateMeasurementMutation();
    const [createAppearance, {isLoading: appearLoading}] = useCreateAppearanceMutation();


    //states
    const [master, setMaster] = useState([])
    const [seller, setSeller] = useState([])
    const [plan, setPlan] = useState({})
    const [url, setUrl] = useState([])
    const [videoUrl, setVideoUrl] = useState([])
    const [waist, setWaist] = useState('')
    const [height, setHeight] = useState('')
    const [heightFeet, setHeightFeet] = useState('')
    const [heightInch, setHeightInch] = useState('')
    const [hips, setHips] = useState('')
    const [bust, setBust] = useState('')
    const [color, setColor] = useState('')
    const [condition, setCondition] = useState('')
    const [fabric, setFabric] = useState('')

    const [youtubeUrl, setYoutubeUrl] = useState('')
    const [youtubeUrlList, setYoutubeUrlList] = useState([])

    const [formData, setFormData] = useState({
        seller_id: "",
        plan_id: "",
        seller_type: "",
        name: "",
        label: "",
        alternative_size: "",
        waist: "",
        height: "",
        hips: "",
        bust: "",
        designer_id: "",
        ethnic_id: "",
        color: "",
        condition: "",
        last_worn: dayjs(),
        fabric: "",
        year: dayjs(),
        silhouette_id: "",
        neckline_id: "",
        back_id: "",
        sleeve_id: "",
        length_id: "",
        description: "",
        is_dry_cleaned: 0,
        is_hand_made: 0,
        is_allow_returns: 0,
        price: "",
        offer_price: "",
        offer_type: "fixed",
        is_bid: 0,
    })

    const [fileName, setFileName] = useState([])
    const [videoNames, setVideoNames] = useState([])


    const handleFormChange = (value, name) => {
        if (name === "plan_id") {
            master.plans.map((plan) => {
                if (plan.id === value) {
                    setPlan(plan)
                }
            })
            setFormData((state) => ({
                ...state,
                [name]: value,
            }))
            form.setFieldValue(name, value)

            changePlan(value);

        } else {
            setFormData((state) => ({
                ...state,
                [name]: value,
            }))
            form.setFieldValue(name, value)
        }
    }

    const handleSelectOptions = (array, labelasvalue) => {
        if (array?.length >= 1) {
            const options = array.map((item) => {
                if (labelasvalue === "labelasvalue") {
                    return {
                        label: item,
                        value: item,
                    }
                } else if (labelasvalue === "seller_name") {
                    return {
                        label: item.name + " (" + item.email + ")",
                        value: item.id,
                    }
                } else {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                }
            })
            return options
        }
    }


    const changePlan = (value) => {
        let newSeller = allSeller;
        if (value !== 4) {
            newSeller = allSeller?.filter((item) => item.paypal_merchant_status === 'verified')
        }
        setSeller(newSeller);
    }

    const handleFinish = async (values) => {
        if (values.last_worn) {
            values.last_worn = convertDayjsToDate(values?.last_worn)
        }
        if (values.year) {
            values.year = dayjs(values?.year).format("YYYY")
        }

        values.is_direct_sold = directSold;


        const data = {
            ...values,
            images: fileName.filter((item) => item !== "" && item),
            videos: videoNames.map((item) => item !== "" && item)
        }

        data.offer_type = 'fixed';

        if (youtubeUrlList.length > 0) {
            data.videos = [...data.videos, ...youtubeUrlList]
        }

        if (data.images.length > 0) {
            const primaryImage = data.images.filter((item) => item.is_default === 1 || item.is_default === true)
            if (primaryImage.length === 0) {
                errorAlert("Please select primary image")
                return
            }
        } else {
            errorAlert("Please select at least one image")
            return
        }

        if (id) {
            updateListing({id, data})
                .unwrap()
                .then((response) => {
                    successAlert(response?.message)
                    navigate("/listing/view/" + id)
                })
                .catch((error) => errorAlert(error?.data?.error))
        } else {
            createListing(data)
                .unwrap()
                .then((response) => {
                    if (response?.data?.paypal?.payer_action_url) {
                        window.location.href = response?.data?.paypal.payer_action_url;
                    } else {
                        successAlert(response?.message)
                        navigate("/listing/view/" + response?.data?.id)
                    }
                    return false;
                })
                .catch((err) => {
                    errorAlert(err?.data?.error)
                })
        }
    }

    const addYoutubeUrl = () => {

        let url = youtubeUrl;

        if (youtubeUrl) {
            if (youtubeUrl.includes("https://youtu.be")) {
                url = youtubeUrl.replace("youtu.be", "youtube.com/embed")
            } else if (!youtubeUrl.includes("https://www.youtube")) {
                url = "https://www.youtube.com/embed/" + youtubeUrl
            } else if (!youtubeUrl.includes("embed")) {
                url = youtubeUrl.replace("watch?v=", "embed/")
            }
            setYoutubeUrlList([...youtubeUrlList, {
                video: url,
                source: "youtube",
            }])
            setYoutubeUrl('')
        }
    }

    const deleteYoutubeUrl = (index) => {
        const newList = [...youtubeUrlList]
        newList.splice(index, 1)
        setYoutubeUrlList(newList)
    }

    const saveMeasurement = async (type, value) => {

        let measurement = '';
        let response = null;

        if(type !== 'height') {
            if (value > 100) {
                errorAlert("Please enter valid "+type)
                return false;
            }
        }

        if (type === 'waist') {
            measurement = master?.measurements?.waist;
            setWaist(null);
        } else if (type === 'height') {
            measurement = master?.measurements?.height;
            setHeight(null);
            setHeightFeet(null);
            setHeightInch(null);
        } else if (type === 'bust') {
            measurement = master?.measurements?.bust;
            setBust(null);
        } else if (type === 'hips') {
            measurement = master?.measurements?.hips;
            setHips(null);
        }

        if (value === null || value === '')
            return false;

        try {
            response = await createMeasurement({
                tag: type,
                name: value,
                status: 1,
            }).unwrap();
            successAlert(response?.message);
            measurement = [...measurement, value]
            setMaster((state) => ({
                ...state,
                measurements: {
                    ...state.measurements,
                    [type]: measurement
                }
            }))
        } catch (e) {
            errorAlert(e);
        }
        return true;
    }

    const saveAppearance = async (type, value) => {

        let appearance = '';
        let response = null;
        if (type === 'color') {
            appearance = master?.appearances?.color;
            setColor(null);
        } else if (type === 'condition') {
            appearance = master?.appearances?.condition;
            setCondition(null);
        } else if (type === 'fabric') {
            appearance = master?.appearances?.fabric;
            setFabric(null);
        }

        if (value === null || value === '')
            return false;

        try {
            response = await createAppearance({
                tag: type,
                name: value,
                status: 1,
            }).unwrap();
            successAlert(response?.message);
            appearance = [...appearance, value]
            setMaster((state) => ({
                ...state,
                appearances: {
                    ...state.appearances,
                    [type]: appearance
                }
            }))
        } catch (e) {
            errorAlert(e);
        }
        return true;
    }


    useEffect(() => {
        if (location?.pathname) {
            const path = location.pathname.includes('direct-sold');
            if (path) {
                setDirectSold(true)
            } else {
                setDirectSold(false)
            }
        }
    }, [location]);

    useEffect(() => {
        if (masterData) {
            setMaster(masterData.data)
        }
    }, [masterData])
    useEffect(() => {
        if (sellerData) {
            setSeller(sellerData.data.sellers);
            setAllSeller(sellerData.data.sellers);
        }
    }, [sellerData])


    useEffect(() => {
        if (id) {
            if (listingView) {
                const {last_worn, year, images, videos, plan_id, ...others} = listingView?.data
                master?.plans?.forEach((item) => {
                    if (item.id === plan_id) {
                        setPlan(item);
                        changePlan(plan_id)
                    }
                })
                const date = last_worn ? dayjs(last_worn) : '';
                const years = year ? dayjs(year) : "";
                setFormData({
                    last_worn: date,
                    plan_id: plan_id,
                    year: years,
                    ...others,
                })
                form.setFieldsValue({
                    year: years,
                    last_worn: date,
                    plan_id: plan_id,
                    ...others,
                })

                // filter videos type from youtube and other
                const youtubeVideos = videos.filter((item) => item.source === "youtube")
                const otherVideos = videos.filter((item) => item.source !== "youtube")

                setUrl(images)
                setVideoUrl(otherVideos)
                setFileName(images)
                setVideoNames(otherVideos)
                setYoutubeUrlList(youtubeVideos)

            }
        }
    }, [listingView, id, master])

    return (
        <Layout.Content>
            <Spin
                spinning={
                    masterLoading === true || sellerLoading === true || listingViewLoading === true
                        ? true
                        : false
                }>
                <Form
                    initialValues={{
                        is_allow_returns: 0,
                        is_bid: 0,
                        is_dry_cleaned: 0,
                        is_hand_made: 0,
                        offer_type: "fixed",
                    }}
                    onFinishFailed={(values) => {

                        errorAlert('Please fill all the required fields');

                        // values.errorFields.forEach((item, index) => {
                        //     if (index <= 4) {
                        //         const name =
                        //             item.name[0].charAt(0).toUpperCase() + item.name[0].slice(1)
                        //         const error = `${String(name).replace("_", " ")} is ${item.errors}`
                        //         errorAlert(error)
                        //     }
                        // })
                    }}
                    className="listing_wrapper"
                    form={form}
                    onFinish={handleFinish}>
                    <Row gutter={[20, 20]} align="middle" className="listing_box">
                        <Form.Item name={"neckline_id"} className="d-none">
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"back_id"} className="d-none">
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"sleeve_id"} className="d-none">
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"length_id"} className="d-none">
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"silhouette_id"} className="d-none">
                            <Input/>
                        </Form.Item>

                        <Col span={12} className="listing_seller_plan_col">
                            <Form.Item
                                className={id ? "event-none" : ""}
                                name={"plan_id"}
                                label={"Plans"}
                                wrapperCol={{
                                    span: 12,
                                }}
                                rules={[{required: true, message: "Required!"}]}>
                                <Select
                                    placeholder="Search Plan"
                                    onChange={(value) => handleFormChange(value, "plan_id")}
                                    options={handleSelectOptions(master?.plans ?? [])}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12} className="listing_seller_plan_col">
                            <Form.Item
                                className={id ? "event-none" : ""}
                                name={"seller_id"}
                                label={"Seller"}
                                wrapperCol={{
                                    span: 24,
                                }}
                                rules={[{required: true, message: "Required!"}]}>
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleFormChange(value, "seller_id")}
                                    options={handleSelectOptions(seller, 'seller_name')}
                                    placeholder="Seller Name"
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={[20, 20]} align="middle" className="listing_box">

                        <Typography.Text>Let’s List your dress</Typography.Text>
                        <Col span={24}>
                            <Row gutter={[20, 20]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={"name"}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        rules={[{required: true, message: "Required!"}]}
                                        label={"Dress Name"}>
                                        <Input
                                            onChange={(e) =>
                                                handleFormChange(e.target.value, "name")
                                            }
                                            placeholder="Dress Name"></Input>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"seller_type"}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        rules={[{required: true, message: "Required!"}]}
                                        label={"Seller Type"}>
                                        <Select
                                            placeholder="Seller Type"
                                            onChange={(value) =>
                                                handleFormChange(value, "seller_type")
                                            }
                                            options={handleSelectOptions(
                                                master?.seller_types ?? []
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"dress_type"}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        rules={[{required: true, message: "Required!"}]}
                                        label={"Dress Type"}>
                                        <Select
                                            placeholder="Dress Type"
                                            onChange={(value) => handleFormChange(value, "dress_type")}
                                            options={handleSelectOptions(
                                                master?.dress_types ?? [],
                                                "labelasvalue"
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"status"}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        rules={[{required: true, message: "Required!"}]}
                                        label={"Status"}>
                                        <Select
                                            placeholder="Status"
                                            onChange={(value) => handleFormChange(value, "status")}
                                            options={handleSelectOptions(
                                                ['draft','pending','active','in active','expired','sold progress','sold','in progress'],
                                                "labelasvalue"
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <span>
                                <Typography.Text className="listing_title" level={4}>
                                    Photos{" "}
                                </Typography.Text>
                                {fileName.length > 0 ? fileName.length : 0} of{" "}
                                {plan?.image_count ?? 4}
                            </span>
                        </Col>
                        <Col span={24}>
                            <span>
                                <Typography.Text>{dimensionsText('listing')}</Typography.Text>
                            </span>

                            <Row>
                                <Col span={24} style={{
                                        minHeight: '300px',
                                    }}>
                                    <Form.Item name="images">
                                        <ImageMultiUpload
                                            tag={"listing"}
                                            url={url}
                                            maxCount={plan.image_count}
                                            label={"picture-card"}
                                            onChange={(image, type) => {
                                                if (type === "delete") {
                                                    setFileName((state) =>
                                                        state.filter((file) => file.image !== image)
                                                    )
                                                } else if (type === "update") {
                                                    setFileName(image);
                                                } else {
                                                    setFileName((state) => {
                                                        return [...state, image]
                                                    })
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        {plan.video_count ? (
                            <>
                                <Col span={24}>
                                    <span>
                                        <Typography.Text className="listing_title" level={4}>
                                            Videos{" "}
                                        </Typography.Text>

                                        {videoNames.length + youtubeUrlList.length + " of " + plan?.video_count}
                                    </span>

                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item name="videos">
                                                <VideoMultiUpload
                                                    url={videoUrl}
                                                    maxCount={plan?.video_count - youtubeUrlList.length}
                                                    label={"picture-card"}
                                                    onChange={(video, type) => {
                                                        if (type === "delete") {
                                                            setVideoNames((state) =>
                                                                state.filter((file) => file.video !== video)
                                                            )
                                                        } else if (type === "update") {
                                                            setVideoNames(video);
                                                        } else {
                                                            setVideoNames((state) => {
                                                                return [...state, video]
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[10, 10]}>

                                        {
                                            videoNames.length + youtubeUrlList.length < plan?.video_count && (
                                                <Col span={24}>
                                                    <Space.Compact
                                                        style={{
                                                            width: '100%',
                                                        }}>
                                                        <Input placeholder="Please Enter Youtube embeded url"
                                                               value={youtubeUrl}
                                                               onChange={(e) => setYoutubeUrl(e.target.value)}
                                                        />
                                                        <Button type="primary"
                                                                onClick={addYoutubeUrl}
                                                        >Add</Button>
                                                    </Space.Compact>
                                                </Col>
                                            )
                                        }

                                        <Col span={24}>
                                            <Row gutter={[24, 16]}>
                                                {youtubeUrlList.map((item, index) => {
                                                    return (
                                                        <Col span={6}>
                                                            <Card
                                                                className='deleteVideo'
                                                                actions={[
                                                                    <DeleteOutlined
                                                                        onClick={() => deleteYoutubeUrl(index)}/>,
                                                                ]}
                                                                style={{width: '100%'}}>

                                                                <iframe src={item.video} width={'100%'} height={150}
                                                                        allowFullScreen title='video'/>


                                                            </Card>

                                                        </Col>
                                                    )
                                                })}
                                            </Row>


                                        </Col>
                                    </Row>

                                </Col>
                            </>
                        ) : null}

                    </Row>
                    <Row gutter={[20, 20]} className="listing_box">
                        <Col span={24}>
                            <Typography.Title level={3}>Size</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[40, 40]}>
                                <Col span={12}>
                                    <Form.Item
                                        name="label"
                                        label="Label"
                                        rules={[{required: true, message: "Required!"}]}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}>
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            placeholder="Label Size"
                                            onChange={(value) => handleFormChange(value, "label")}
                                            options={handleSelectOptions(
                                                master?.sizes?.label ?? [],
                                                "labelasvalue"
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        label="Alternative Size"
                                        name={"alternative_size"}>
                                        <Input
                                            placeholder="Alternative Size"
                                            onChange={(e) =>
                                                handleFormChange(e.target.value, "alternative_size")
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Typography.Title>Measurements</Typography.Title>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Waist"
                                        rules={[{required: false, message: "Required!"}]}
                                        wrapperCol={{
                                            span: 20,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="waist">
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            placeholder="Waist Size"
                                            onChange={(value) => handleFormChange(value, "waist")}
                                            options={handleSelectOptions(
                                                master?.measurements?.waist ?? [],
                                                "labelasvalue"
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{margin: '8px 0'}}/>
                                                    <Space style={{padding: '0 8px 4px'}}>
                                                        <Input
                                                            placeholder="Waist"
                                                            value={waist}
                                                            onKeyPress={onlyNumber}
                                                            onChange={(e) => setWaist(e.target.value)}
                                                        />
                                                        <Button type="text"
                                                                loading={mesLoading}
                                                                onClick={
                                                                    () => {
                                                                        if (waist) {
                                                                            saveMeasurement('waist', waist)
                                                                        }
                                                                    }
                                                                }>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Height"
                                        rules={[{required: false, message: "Required!"}]}
                                        wrapperCol={{
                                            span: 20,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="height">
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            onChange={(value) => handleFormChange(value, "height")}
                                            placeholder="Height Size"
                                            options={handleSelectOptions(
                                                master?.measurements?.height ?? [],
                                                "labelasvalue"
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{margin: '8px 0'}}/>
                                                    <Space style={{padding: '0 8px 4px'}}>
                                                        <Input
                                                            placeholder="Feet"
                                                            value={heightFeet}
                                                            onKeyPress={onlyNumber}
                                                            onChange={(e) => setHeightFeet(e.target.value)}
                                                        />
                                                        <Input
                                                            placeholder="Inch"
                                                            value={heightInch}
                                                            onKeyPress={onlyNumber}
                                                            onChange={(e) => setHeightInch(e.target.value)}
                                                        />
                                                        <Button type="text"
                                                                loading={mesLoading}
                                                                onClick={
                                                                    () => {
                                                                        let value = '';
                                                                        if (heightFeet && heightInch)
                                                                            value = heightFeet + "'" + heightInch + '"'
                                                                        else if (heightFeet)
                                                                            value = heightFeet + "'"

                                                                        if (value) {
                                                                            saveMeasurement('height', value)
                                                                        }
                                                                    }
                                                                }>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Hips"
                                        rules={[{required: false, message: "Required!"}]}
                                        wrapperCol={{
                                            span: 20,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="hips">
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            placeholder="Hip Size"
                                            onChange={(value) => handleFormChange(value, "hips")}
                                            options={handleSelectOptions(
                                                master?.measurements?.hips ?? [],
                                                "labelasvalue"
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{margin: '8px 0'}}/>
                                                    <Space style={{padding: '0 8px 4px'}}>
                                                        <Input
                                                            placeholder="Hips"
                                                            value={hips}
                                                            onKeyPress={onlyNumber}
                                                            onChange={(e) => setHips(e.target.value)}
                                                        />
                                                        <Button type="text"
                                                                loading={mesLoading}
                                                                onClick={
                                                                    () => {
                                                                        if (hips) {
                                                                            saveMeasurement('hips', hips)
                                                                        }
                                                                    }
                                                                }>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[{required: false, message: "Required!"}]}
                                        label="Bust"
                                        wrapperCol={{
                                            span: 20,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="bust">
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            onChange={(value) => handleFormChange(value, "bust")}
                                            placeholder="Bust Size"
                                            options={handleSelectOptions(
                                                master?.measurements?.bust ?? [],
                                                "labelasvalue"
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{margin: '8px 0'}}/>
                                                    <Space style={{padding: '0 8px 4px'}}>
                                                        <Input
                                                            placeholder="Bust"
                                                            value={bust}
                                                            onKeyPress={onlyNumber}
                                                            onChange={(e) => setBust(e.target.value)}
                                                        />
                                                        <Button type="text"
                                                                loading={mesLoading}
                                                                onClick={
                                                                    () => {
                                                                        if (bust) {
                                                                            saveMeasurement('bust', bust)
                                                                        }
                                                                    }
                                                                }>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item

                                        rules={[{required: true, message: "Required!"}]}
                                        label="Weight (Pounds)"
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="weight">
                                        <InputNumber
                                            style={{width: '100%'}}
                                            controls={false}
                                            placeholder="Weight (Pounds)"
                                            onChange={(e) =>
                                                handleFormChange(e, "weight")
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]} className="listing_box">
                        <Col span={24}>
                            <Typography.Title level={3}>Designer</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[40, 40]}>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[{required: true, message: "Required!"}]}
                                        name={"designer_id"}
                                        label="Select Designer"
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}>
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            placeholder="Select Designer"
                                            onChange={(value) =>
                                                handleFormChange(value, "designer_id")
                                            }
                                            options={handleSelectOptions(master?.designers)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]} className="listing_box">
                        <Col span={24}>
                            <Typography.Title level={3}>Ethnic</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[40, 40]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Select Ethnic"
                                        name={"ethnic_id"}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}>
                                        <Select
                                            showSearch
                                            allowClear={true}
                                            optionFilterProp="label"
                                            placeholder="Select ethnic"
                                            onChange={(value) =>
                                                handleFormChange(value, "ethnic_id")
                                            }
                                            options={handleSelectOptions(master?.ethnic)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]} className="listing_box">
                        <Col span={24}>
                            <Typography.Title level={3}>Appearance</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[40, 40]}>
                                <Col span={8}>
                                    <Form.Item
                                        label="Fabric"
                                        rules={[{required: true, message: "Required!"}]}

                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="fabric">
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            onChange={(value) => handleFormChange(value, "fabric")}
                                            placeholder="Fabric"
                                            options={handleSelectOptions(
                                                master?.appearances?.fabric ?? [],
                                                "labelasvalue"
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{margin: '8px 0'}}/>
                                                    <Space style={{padding: '0 8px 4px'}}>
                                                        <Input
                                                            placeholder="Fabric"
                                                            value={fabric}
                                                            onChange={(e) => setFabric(e.target.value)}
                                                        />
                                                        <Button type="text"
                                                                loading={appearLoading}
                                                                onClick={
                                                                    () => {
                                                                        if (fabric) {
                                                                            saveAppearance('fabric', fabric)
                                                                        }
                                                                    }
                                                                }>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[{required: true, message: "Required!"}]}

                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        label="Color"
                                        name="color">
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            placeholder="Color"
                                            onChange={(value) => handleFormChange(value, "color")}
                                            options={handleSelectOptions(
                                                master?.appearances?.color ?? [],
                                                "labelasvalue"
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{margin: '8px 0'}}/>
                                                    <Space style={{padding: '0 8px 4px'}}>
                                                        <Input
                                                            placeholder="Color"
                                                            value={color}
                                                            onChange={(e) => setColor(e.target.value)}
                                                        />
                                                        <Button type="text"
                                                                loading={appearLoading}
                                                                onClick={
                                                                    () => {
                                                                        if (color) {
                                                                            saveAppearance('color', color)
                                                                        }
                                                                    }
                                                                }>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="is_dry_cleaned"
                                        label="Dry Cleaned"
                                        valuePropName="checked">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={(value) => {
                                                handleFormChange(value, "is_dry_cleaned")
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        name="is_hand_made"
                                        label="Hand Made"
                                        valuePropName="checked">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={(value) => {
                                                handleFormChange(value, "is_hand_made")
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        rules={[{required: true, message: "Required!"}]}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        label="Condition"
                                        name="condition">
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            onChange={(value) => {
                                                if (value !== "Pre Owned") {
                                                    form.setFieldsValue({last_worn: null});
                                                }
                                                handleFormChange(value, "condition")
                                            }}
                                            placeholder="Condition"
                                            options={handleSelectOptions(
                                                master?.appearances?.condition ?? [],
                                                "labelasvalue"
                                            )}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{margin: '8px 0'}}/>
                                                    <Space style={{padding: '0 8px 4px'}}>
                                                        <Input
                                                            placeholder="Condition"
                                                            value={condition}
                                                            onChange={(e) => setCondition(e.target.value)}
                                                        />
                                                        <Button type="text"
                                                                loading={appearLoading}
                                                                onClick={
                                                                    () => {
                                                                        if (condition) {
                                                                            saveAppearance('condition', condition)
                                                                        }
                                                                    }
                                                                }>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} className={
                                    form.getFieldValue("condition") !== "Pre Owned" ? "d-none" : ""
                                }>
                                    <Form.Item
                                        rules={[{
                                            required: form.getFieldValue("condition") === "Pre Owned",
                                            message: "Required!"
                                        }]}
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        label="Last Worn"
                                        name="last_worn">
                                        <DatePicker placeholder="Last Worn"
                                                    picker="year"
                                                    format="YYYY"
                                                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        wrapperCol={{
                                            span: 12,
                                        }}
                                        labelCol={{
                                            span: 24,
                                        }}
                                        label="Year"
                                        name="year">

                                        <DatePicker placeholder="Year" picker="year"
                                                    disabledDate={(current) => current && current > dayjs().endOf('day')}

                                        />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]} className="listing_box">
                        <Col span={24}>
                            <Typography.Title level={3}>Styles</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[40, 30]}>
                                <Col span={24}>
                                    <span
                                        style={{
                                            fontSize: "20px",
                                        }}>
                                        Silhoutte
                                    </span>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[20, 20]}>
                                        {master?.styles?.silhouette?.map((item, index) => {
                                            if (item.tag === "silhouette") {
                                                return (
                                                    <Col
                                                        key={index}
                                                        span={3}
                                                        onClick={() =>
                                                            handleFormChange(
                                                                item.id,
                                                                `${item.tag}_id`
                                                            )
                                                        }>
                                                        <section
                                                            className={
                                                                item.id ===
                                                                formData[`${item.tag}_id`]
                                                                    ? "silhouette_photo_active silhouette_photo_1"
                                                                    : "silhouette_photo_1"
                                                            }>
                                                            <img src={item.image} alt=""></img>
                                                            <span>{item.name}</span>
                                                        </section>
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Row
                            gutter={[40, 30]}
                            style={{
                                paddingTop: "40px",
                            }}>
                            <Col span={24}>
                                <span
                                    style={{
                                        fontSize: "20px",
                                    }}>
                                    Neck Line
                                </span>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    {master?.styles?.neckline?.map((item, index) => {
                                        if (item.tag === "neckline") {
                                            return (
                                                <Col
                                                    span={3}
                                                    key={index}
                                                    onClick={() =>
                                                        handleFormChange(item.id, `${item.tag}_id`)
                                                    }>
                                                    <section
                                                        className={
                                                            item.id === formData[`${item.tag}_id`]
                                                                ? "silhouette_photo_active silhouette_photo_1"
                                                                : "silhouette_photo_1"
                                                        }>
                                                        <img src={item.image} alt={item.name}/>
                                                        <span>{item.name}</span>
                                                    </section>
                                                </Col>
                                            )
                                        }
                                    })}
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            gutter={[40, 30]}
                            style={{
                                paddingTop: "40px",
                            }}>
                            <Col span={24}>
                                <span
                                    style={{
                                        fontSize: "20px",
                                    }}>
                                    Back
                                </span>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    {master?.styles?.back?.map((item, index) => {
                                        if (item.tag === "back") {
                                            return (
                                                <Col
                                                    span={3}
                                                    key={index}
                                                    onClick={() =>
                                                        handleFormChange(item.id, `${item.tag}_id`)
                                                    }>
                                                    <section
                                                        className={
                                                            item.id === formData[`${item.tag}_id`]
                                                                ? "silhouette_photo_active silhouette_photo_1"
                                                                : "silhouette_photo_1"
                                                        }>
                                                        <img src={item.image} alt={item.name}/>
                                                        <span>{item.name}</span>
                                                    </section>
                                                </Col>
                                            )
                                        }
                                    })}
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            gutter={[40, 30]}
                            style={{
                                paddingTop: "40px",
                            }}>
                            <Col span={24}>
                                <span
                                    style={{
                                        fontSize: "20px",
                                    }}>
                                    Sleeve
                                </span>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    {master?.styles?.sleeve?.map((item, index) => {
                                        if (item.tag === "sleeve") {
                                            return (
                                                <Col
                                                    span={3}
                                                    key={index}
                                                    onClick={() =>
                                                        handleFormChange(item.id, `${item.tag}_id`)
                                                    }>
                                                    <section
                                                        className={
                                                            item.id === formData[`${item.tag}_id`]
                                                                ? "silhouette_photo_active silhouette_photo_1"
                                                                : "silhouette_photo_1"
                                                        }>
                                                        <img src={item.image} alt={item.name}/>
                                                        <span>{item.name}</span>
                                                    </section>
                                                </Col>
                                            )
                                        }
                                    })}
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            gutter={[40, 30]}
                            style={{
                                paddingTop: "40px",
                            }}>
                            <Col span={24}>
                                <span
                                    style={{
                                        fontSize: "20px",
                                    }}>
                                    Length
                                </span>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[20, 20]}>
                                    {master?.styles?.length?.map((item, index) => {
                                        if (item.tag === "length") {
                                            return (
                                                <Col
                                                    span={3}
                                                    key={index}
                                                    onClick={() =>
                                                        handleFormChange(item.id, `${item.tag}_id`)
                                                    }>
                                                    <section
                                                        className={
                                                            item.id === formData[`${item.tag}_id`]
                                                                ? "silhouette_photo_active silhouette_photo_1"
                                                                : "silhouette_photo_1"
                                                        }>
                                                        <img src={item.image} alt={item.name}/>
                                                        <span>{item.name}</span>
                                                    </section>
                                                </Col>
                                            )
                                        }
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row gutter={[20, 20]} className="listing_box">
                        <Col
                            span={24}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "50px",
                            }}>
                            <Row>
                                <Col span={12}>
                                    <Typography.Title level={3}>
                                        About the Dress <QuestionCircleOutlined/>
                                    </Typography.Title>
                                </Col>
                                {/*<Col span={12} className="add_listing_returns">*/}
                                {/*    <Form.Item*/}
                                {/*        name="is_allow_returns"*/}
                                {/*        valuePropName="checked"*/}
                                {/*        label="Allow returns">*/}
                                {/*        <Switch*/}
                                {/*            checkedChildren="Yes"*/}
                                {/*            unCheckedChildren="No"*/}
                                {/*            onChange={(value) =>*/}
                                {/*                handleFormChange(value, "is_allow_returns")*/}
                                {/*            }*/}
                                {/*        />*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Row>
                            <Form.Item name={"description"}>
                                <Input.TextArea
                                    onChange={(e) =>
                                        handleFormChange(e.target.value, "description")
                                    }
                                    className="text_area"
                                    placeholder="Write a description about a dress"></Input.TextArea>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col span={8}>
                            <section className="listing_box_down">
                                <Typography.Title level={3}><span style={{
                                    color: "#ff4d4f",
                                    position: 'relative',
                                    top: '16%'
                                }}>* </span>Price</Typography.Title>
                                <div className="offer_price listing-usd-input">
                                    <Form.Item
                                        labelCol={{
                                            span: 24,
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        name={"price"}
                                        rules={[{required: true, message: "Required!"}]}>
                                        <InputNumber
                                            addonBefore="$USD"
                                            className="price_input"
                                            controls={false}
                                            placeholder="Price"></InputNumber>
                                    </Form.Item>
                                </div>
                            </section>
                        </Col>
                        <Col span={8}>
                            <section className="listing_box_down">
                                <Typography.Title level={3}>Offer Price</Typography.Title>
                                <div className="offer_price listing-usd-input">
                                    <Form.Item name={"offer_price"}>
                                        <InputNumber
                                            controls={false}
                                            addonBefore="$USD"
                                            className="price_input"
                                            placeholder="Offer Price"></InputNumber>
                                    </Form.Item>
                                </div>
                            </section>
                        </Col>
                        <Col span={6} className="listing_box">
                            <Typography.Title
                                style={{
                                    textAlign: "center",
                                }}
                                level={3}>
                                Allow Bid{" "}
                            </Typography.Title>

                            <Form.Item valuePropName="checked" name={"is_bid"} style={{textAlign: "center"}}>
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={(value) =>
                                        handleFormChange(value, "is_bid")
                                    }
                                />


                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]} justify={"center"}>
                        <Col>
                            <Button
                                loading={
                                    createLoading === true || updateLoading === true ? true : false
                                }
                                className="submit_btn"
                                type="primary"
                                htmlType="submit">
                                {id ? "Update Listing" : "Create Listing"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Layout.Content>
    )
}

export default ListingAction
